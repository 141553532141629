import React, { useState } from "react"
import classes from "./portfolio.module.css"
import Root from "../components/root"
import CloseButtonImage from "../gatsbyImages/CloseButtonImage"

import { navigate } from "gatsby"
import clsx from "clsx"

const Description = ({ description, previous }) => (
  <div className={classes.descriptionContainer}>
    <div className="flex justify-between">
      <div className={classes.descriptionHead}>Description & Concept</div>
      <div
        className={clsx(classes.closeBtn, "w-12 mb-20")}
        onClick={() => {
          navigate(previous)
        }}
      >
        <CloseButtonImage />
      </div>
    </div>

    <div className={classes.description}>{description}</div>
  </div>
)

const ProjectTitle = ({ title }) => (
  <div className={classes.projectTitle}>{title}</div>
)

const ThumbnailCard = ({ thumbnail }) => (
  <img className={classes.thumbnailCard} src={thumbnail} />
)

const VideoCard = ({ url }) => (
  <div className={classes.imgCard}>
    <iframe
      allowFullScreen
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      src={url}
      frameBorder="0"
      width="100%"
      height="100%"
    />
  </div>
)

const Portfolio = ({ pageContext }) => {
  const [loadMore, setLoadMore] = useState(false)

  const title = pageContext.title
  const description = pageContext.description
  const thumbnail = pageContext.thumbnail
  const videos = pageContext.videos

  var size = loadMore ? videos?.length : 4

  return (
    <Root className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleMobile}>
          <ProjectTitle title={title} />
        </div>
        <Description
          description={description}
          previous={pageContext.previous}
        />

        <div className={classes.title}>
          <div className={classes.middle}>
            <ThumbnailCard thumbnail={thumbnail} />

            <ProjectTitle title={title} />
          </div>
        </div>

        <div className={classes.grid}>
          {videos?.slice(0, size).map(video => (
            <VideoCard url={video} />
          ))}
        </div>

        {!loadMore && videos?.length > 4 && (
          <div onClick={e => setLoadMore(true)} className={classes.load}>
            LOAD MORE
          </div>
        )}
      </div>
    </Root>
  )
}

export default Portfolio
